<template>
  <b-modal
    ref="paymentModal"
    id="payment_modal"
    hide-footer
    hide-header
    scrollable
  >
    <div>
      <div class="d-flex justify-content-between align-items-center my-2">
        <h3 class="w-700">Create/Edit Payment</h3>
      </div>
      <hr />
      <b-form-group id="lblLoan" label="" label-for="txtLoan">
        <b-input-group>
          <b-form-input
            placeholder="Enter References Code"
            size="sm"
            v-model="searchParams"
            :disabled="!isCreated"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              size="sm"
              variant="info"
              @click="searchLoan"
              :disabled="!isCreated"
              >Search</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <span class="p-font" v-show="form.loan_id.lend_by.full_name != null"
          >{{ form.loan_id.lend_by.full_name }} -
          {{ form.loan_id.reference_code }}</span
        >
      </b-form-group>
      <b-form-group id="lblAmount" label="" label-for="txtLoanAmount">
        <b-form-input
          class="form-control col-12"
          id="txtLoanAmount"
          size="sm"
          name="txtLoanAmount"
          :disabled="!isCreated"
          placeholder="Amount"
          type="number"
          v-model="$v.form.amount.$model"
          :state="validateState('amount')"
          aria-describedby="amount-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="amount-live-feedback">
          Amount is required.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="lblNote" label="Note" label-for="txtNote">
        <b-form-textarea
          class="form-control col-12"
          id="txtNote"
          size="sm"
          name="txtNote"
          :disabled="!isCreated"
          v-model="form.note"
        ></b-form-textarea>
      </b-form-group>
    </div>
    <b-button
      size="sm"
      variant="outline-danger"
      class="mr-1"
      @click="resetForm"
      :disabled="!isCreated"
      >RESET</b-button
    >
    <b-button
      type="submit"
      size="sm"
      variant="outline-primary"
      @click="onSubmit"
      >SUBMIT</b-button
    >
  </b-modal>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "payment-form",
  data() {
    return {
      isCreated: true,
      searchParams: null,
      form: {
        id: 0,
        loan_id: {
          lend_by: {
            fullname: null
          }
        },
        handled_by: {},
        amount: null
      }
    };
  },
  validations: {
    form: {
      amount: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    searchLoan() {
      const params = this.searchParams;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _self = this;
      ApiService.put("loans", { searchParams: params }).then(({ data }) => {
        if (data.isSuccess && data.loan != null) {
          _self.form.loan_id = data.loan;
          _self.form.handled_by = _self.$store.getters.currentUser;
          // Swal.fire({
          //   icon: "success",
          //   text: "Loan selected",
          //   focusConfirm: true
          // });
        } else {
          Swal.fire({
            icon: "error",
            text:
              "Request submittion failed, Try again or contact system admin",
            focusConfirm: true
          });
        }
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.isCreated) {
        ApiService.post("payments", this.form).then(({ data }) => {
          if (data.isSuccess) {
            Swal.fire({
              icon: "success",
              text: "Request is submitted successfully",
              focusConfirm: true
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                "Request submittion failed, Try again or contact system admin",
              focusConfirm: true
            });
          }
        });
      } else {
        ApiService.post("payments/" + this.form.id, this.form).then(
          ({ data }) => {
            if (data.isSuccess) {
              Swal.fire({
                icon: "success",
                text: "Request is submitted successfully",
                focusConfirm: true
              });
              // this.$router.push({
              //   name: "Billing",
              //   params: { id: data.payment_id }
              // });
            } else {
              Swal.fire({
                icon: "error",
                text:
                  "Request submittion failed, Try again or contact system admin",
                focusConfirm: true
              });
            }
          }
        );
      }
      this.$refs["paymentModal"].hide();
      this.$parent.callByComponent();
    },
    resetForm() {
      this.form = {
        id: 0,
        reference_code: null,
        amount: null,
        loan_id: {},
        handled_by: {},
        status: 0
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    getIdFromChild(id) {
      this.$refs.paymentModal.show();
      if (id != 0) {
        ApiService.get("payments/" + id).then(({ data }) => {
          this.isCreated = false;
          this.form = data.payment;
        });
      } else {
        this.isCreated = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.p-font,
.form-group {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
